@import url(https://cdn.syncfusion.com/ej2/21.2.9/tailwind.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: #2d334a;
  
}

.site-layout .site-layout-background {
  background: #2d334a;
}
.App{
  background-color: #2d334a;
}
.MuiDataGrid-row {
    color:#fff;
   
    
}
.MuiDataGrid-cellLeft{
    text-align: center;
}
.MuiTablePagination-toolbar{
    color:#fff
}
.MuiTablePagination-menuItem{
    color:#fff   
}
.MuiTablePagination-actions{
    color:#fff   
}
.MuiIconButton-label{
    color: #fff;
}


.custom-bg-yellow {
    background-color: yellow !important;
    color: black !important;
}

.custom-bg-yellow:hover {
    background-color: rgba(255, 255, 0, 0.75) !important;
    color: black !important;
}

.custom-bg-red {
    background-color: red !important;
    color: white !important;
}
.custom-bg-red:hover {
    background-color: rgba(255, 0, 0, 0.75) !important;
    color: white !important;
}

.custom-bg-green {
    background-color: green !important;
    color: white !important;
}
.custom-bg-green:hover {
    background-color: rgba(0, 128, 0, 0.75) !important;
    color: white !important;
}

.custom-bg-white {
    background-color: white !important;
    color: black !important;
}

.custom-bg-white:hover {
    background-color: rgba(255, 255, 255, 0.75) !important;
    color: black !important;
}
@media print {
    @page { size: landscape; }
  }
  
  table, th, td {
    border: 1px solid black;
  }
   @media print {
    html, body {
      height : auto !important;
      height : initial !important;
      overflow: visible !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @page{
    size: auto;
    margin: 20mm;
  } 
  /* @media all {
    .page-break{
      display: none;
    }
  }
  @media print{
    .page-break{
      display: block;
      page-break-before: auto;
    }
  } */
  /* #pageFooter{
    display: table-footer-group;
  }
  #pageFooter::after{
    counter-increment: page;
    content: counter(page);
  } */
  /* @page{
    @bottom-left{
      content: counter(page) "/" counter(pages);
    }
  } */
  .Header_deisolation {
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color:#deebf7;
    color: #404040;
    padding: 1em;
    /* margin-bottom: 2em; */
    height: 15vh;
   
  }
  
  
  .logo_deisolation{
   height: 100px;
   padding-right: 20%;
   display: -webkit-flex;
   display: flex;
   
  }
  .logo2_deisolation{
    height: 100px;
   padding-left: 20%;
    display: -webkit-flex;
    display: flex;
   
   }
   .Title_deisolation{
     text-align: center;
     margin-top: -8px;
     font-size: 20px !important;
     font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
   }
   .Header2_deisolation{
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color: #203864;
    color: rgb(12, 12, 12);
    padding: 1em;
    margin-bottom: 2em;
    height: 6vh;
  }
   .Title2_deisolation{
    
    text-align: center;
    margin-top:0px;
    color: #fffef9;
    font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
     font-size: 18;
    
   }
   .Date_deisolation{
    padding-right: 25%;
    color: #fffef9;
    
   }
   .Time_deisolation{
     padding-left:25%;
     color: #fffef9;
   }
.DateRange_CalenderIcon__3gioK {
    -webkit-flex: 1 1;
            flex: 1 1;
    font-size: 20px;
    margin-right: 10px;
}
html {
    background-color: #000000;
  }
  
  body {
    font-family: "Poppins", sans-serif;
    height: 100vh;
    background-color: #000000;
  }
  
  a {
    color: #92badd;
    display:inline-block;
    text-decoration: none;
    font-weight: 400;
  }
  
  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display:inline-block;
    margin: 40px 8px 10px 8px; 
    color: #cccccc;
  }
  
  
  
  /* STRUCTURE */
  
  .wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column; 
    -webkit-justify-content: center; 
            justify-content: center;
    width: 100%;
    min-height: 50%;
    padding: 20px;
    background-image: url(/static/media/bg.3a2c162a.jpg);
    background-size: cover;
    background-position: top;    
  }
  
  #formContent {
    border-radius: 10px 10px 10px 10px;
    background: rgb(1, 39, 88);
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0px;
    box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
    text-align: center;
  }
  
  #formFooter {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 25px;
    text-align: center;
    border-radius: 0 0 10px 10px;
  }
  
  
  
  /* TABS */
  
  h2.inactive {
    color: #cccccc;
  }
  
  h2.active {
    color: #0d0d0d;
    border-bottom: 2px solid #5fbae9;
  }
  
  
  
  /* FORM TYPOGRAPHY*/
  
  input[type=button], input[type=submit], input[type=reset]  {
    background-color: #56baed;
    border: none;
    color: white;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    transition: all 0.3s ease-in-out;
  }
  
  input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
    background-color: #39ace7;
  }
  
  input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  
  input[type=text],[type=password] {
    background-color: #f6f6f6;
    border: none;
    color: #0d0d0d;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    width: 85%;
    border: 2px solid #f6f6f6;
    transition: all 0.5s ease-in-out;
    border-radius: 5px 5px 5px 5px;
  }
  
  input[type=text]:focus,[type=password]:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
  }
  
  input[type=text]:placeholder,[type=password]:placeholder {
    color: #cccccc;
  }
  
  
  
  /* ANIMATIONS */
  
  /* Simple CSS3 Fade-in-down Animation */
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  /* Simple CSS3 Fade-in Animation */
  @-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
  @keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
  
  .fadeIn {
    opacity:0;
    -webkit-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;
  
    -webkit-animation-fill-mode:forwards;
    animation-fill-mode:forwards;
  
    -webkit-animation-duration:1s;
    animation-duration:1s;
  }
  
  .fadeIn.first {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  
  .fadeIn.second {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  
  .fadeIn.third {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }
  
  .fadeIn.fourth {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  
  /* Simple CSS3 Fade-in Animation */
  .underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #56baed;
    content: "";
    transition: width 0.2s;
  }
  
  .underlineHover:hover {
    color: #0d0d0d;
  }
  
  .underlineHover:hover:after{
    width: 100%;
  }
  
  
  
  /* OTHERS */
  
  *:focus {
      outline: none;
  } 
  
  #icon {
    width:40%;
  }
.MuiDataGrid-row {
  color: #fff;
}
.MuiDataGrid-cellLeft {
  text-align: center;
}
.MuiTablePagination-toolbar {
  color: #fff;
}
.MuiTablePagination-menuItem {
  color: #fff;
}
.MuiTablePagination-actions {
  color: #fff;
}
.MuiIconButton-label {
  color: #fff;
}

@media print {
    @page { size: landscape; }
  }
  
  table, th, td {
    border: 1px solid black;
  }
   @media print {
    html, body {
      height : auto !important;
      height : initial !important;
      overflow: visible !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @page{
    size: auto;
    margin: 20mm;
  } 
  /* @media all {
    .page-break{
      display: none;
    }
  }
  @media print{
    .page-break{
      display: block;
      page-break-before: auto;
    }
  } */
  /* #pageFooter{
    display: table-footer-group;
  }
  #pageFooter::after{
    counter-increment: page;
    content: counter(page);
  } */
  /* @page{
    @bottom-left{
      content: counter(page) "/" counter(pages);
    }
  } */
  .Header {
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color:#deebf7;
    color: #404040;
    padding: 1em;
    /* margin-bottom: 2em; */
    height: 15vh;
   
  }
  
  
  .logo{
   height: 100px;
   padding-right: 20%;
   display: -webkit-flex;
   display: flex;
   
  }
  .logo2{
    height: 100px;
   padding-left: 20%;
    display: -webkit-flex;
    display: flex;
   
   }
   .Title{
     text-align: center;
     margin-top: 30px;
     font-size: 20px;
     font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
   }
   .Header2{
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color: #203864;
    color: rgb(12, 12, 12);
    padding: 1em;
    margin-bottom: 2em;
    height: 6vh;
  }
   .Title2{
    
    text-align: center;
    margin-top:0px;
    color: #fffef9;
    font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
     font-size: 18;
    
   }
   .Date{
    padding-right: 25%;
    color: #fffef9;
    
   }
   .Time{
     padding-left:25%;
     color: #fffef9;
   }
   
.MuiTypography-body1{
    line-height: 0.5;
}
.MuiDataGrid-row {
    color:#fff;
   
    
}
.MuiDataGrid-cellLeft{
    text-align: center;
}
.MuiTablePagination-toolbar{
    color:#fff
}
.MuiTablePagination-menuItem{
    color:#fff   
}
.MuiTablePagination-actions{
    color:#fff   
}
.MuiIconButton-label{
    color: #fff;
}

@media print {
    @page { size: landscape; }
  }
  
  table, th, td {
    border: 1px solid black;
  }
   @media print {
    html, body {
      height : auto !important;
      height : initial !important;
      overflow: visible !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @page{
    size: auto;
    margin: 20mm;
  } 
  /* @media all {
    .page-break{
      display: none;
    }
  }
  @media print{
    .page-break{
      display: block;
      page-break-before: auto;
    }
  } */
  /* #pageFooter{
    display: table-footer-group;
  }
  #pageFooter::after{
    counter-increment: page;
    content: counter(page);
  } */
  /* @page{
    @bottom-left{
      content: counter(page) "/" counter(pages);
    }
  } */
.MuiDataGrid-row {
    color:#fff;
   
    
}
.MuiDataGrid-cellLeft{
    text-align: center;
}
.MuiTablePagination-toolbar{
    color:#fff
}
.MuiTablePagination-menuItem{
    color:#fff   
}
.MuiTablePagination-actions{
    color:#fff   
}
.MuiIconButton-label{
    color: #fff;
}

@media print {
  @page {
    size: A4 !important;
    margin: 10%;
  }
}

/* table, th, td {
  border: 1px solid black;
} */
@media print {
  html,
  body {
    height: auto !important;
    height: initial !important;
    overflow: visible !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    background-color: #fff !important;
    /* margin-left:2px;
    margin-right: 10x; */
    width: auto !important;
  }
}
@page {
  size: auto;
  /* margin: 20mm; */
}
.pdfTable .pdfHead .pdfTr {
  border: none !important;
}

.pdf_CalenderIcon__1TQkr {
    -webkit-flex: 1 1;
            flex: 1 1;
    font-size: 20px;
    margin-right: 10px;
}

.MuiDataGrid-row {
    color:#fff;
   
    
}
.MuiDataGrid-cellLeft{
    text-align: center;
}
.MuiTablePagination-toolbar{
    color:#fff
}
.MuiTablePagination-menuItem{
    color:#fff   
}
.MuiTablePagination-actions{
    color:#fff   
}
.MuiIconButton-label{
    color: #fff;
}

@media print {
    @page { size: landscape; }
  }
  
  table, th, td {
    border: 1px solid black;
  }
   @media print {
    html, body {
      height : auto !important;
      height : initial !important;
      overflow: visible !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @page{
    size: auto;
    margin: 20mm;
  } 
  /* @media all {
    .page-break{
      display: none;
    }
  }
  @media print{
    .page-break{
      display: block;
      page-break-before: auto;
    }
  } */
  /* #pageFooter{
    display: table-footer-group;
  }
  #pageFooter::after{
    counter-increment: page;
    content: counter(page);
  } */
  /* @page{
    @bottom-left{
      content: counter(page) "/" counter(pages);
    }
  } */
.MuiDataGrid-row {
  color: #fff;
}
.MuiDataGrid-cellLeft {
  text-align: center;
}
.MuiTablePagination-toolbar {
  color: #fff;
}
.MuiTablePagination-menuItem {
  color: #fff;
}
.MuiTablePagination-actions {
  color: #fff;
}
.MuiIconButton-label {
  color: #fff;
}
/* .modal-content {
  width: 100%;
  /* size: A3 landscape !important;
  position: relative;
  font-size: x-small;
  right: 250px; 
} */

@media print {
    @page { size: landscape; }
  }
  
  table, th, td {
    border: 1px solid black;
  }
  @media print {
    html,
    body {
      height: auto !important;
      height: initial !important;
      overflow: visible !important;
      overflow: initial !important;
       -webkit-print-color-adjust: exact; 
       background-color: #fff !important; 
       margin-left:2px;
        margin-right: 10x; 
      width: auto !important;
      /* //border: 1px solid blue; 
      margin: 5px ;
      -webkit-print-content-adjust: exact*/
    }
  }
  @page{
    size: auto;
    margin: 20mm;
  } 
  /* @media all {
    .page-break{
      display: none;
    }
  }
  @media print{
    .page-break{
      display: block;
      page-break-before: auto;
    }
  } */
  /* #pageFooter{
    display: table-footer-group;
  }
  #pageFooter::after{
    counter-increment: page;
    content: counter(page);
  } */
  /* @page{
    @bottom-left{
      content: counter(page) "/" counter(pages);
    }
  } */
  .Header_isolation {
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color:#deebf7;
    color: #404040;
    padding: 1em;
    /* margin-bottom: 2em; */
    height: 15vh;
   
  }
  
  
  .logo_isolation{
   height: 100px;
   padding-right: 20%;
   display: -webkit-flex;
   display: flex;
  
   
  }
  .logo2_isolation{
    height: 100px;
   
   padding-left: 20%;
    display: -webkit-flex;
    display: flex;
   
   }
   .Title_isolation{
     text-align: center;
     margin-top: 10px;
     font-size: 20px !important;
     font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
     /* white-space: nowrap; */
   }
   .Header2_isolation{
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color: #203864;
    color: rgb(12, 12, 12);
    padding: 1em;
    margin-bottom: 2em;
    height: 6vh;
  }
   .Title2_isolation{
    
    text-align: center;
    margin-top:0px;
    color: #fffef9;
    font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
     font-size: 18;
    
   }
   .Date_isolation{
    padding-right: 15%;
    color: #fffef9;
    
   }
   .Time_isolation{
     padding-left:15%;
     color: #fffef9;
   }
.MuiDataGrid-row {
    color:#fff;
   
    
}
.MuiDataGrid-cellLeft{
    text-align: center;
}
.MuiTablePagination-toolbar{
    color:#fff
}
.MuiTablePagination-menuItem{
    color:#fff   
}
.MuiTablePagination-actions{
    color:#fff   
}
.MuiIconButton-label{
    color: #fff;
}

@media print {
    @page { size: landscape; }
  }
  
  table, th, td {
    border: 1px solid black;
  }
   @media print {
    html, body {
      height : auto !important;
      height : initial !important;
      overflow: visible !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
      font-size: large;
    }
  }
  @page{
    size: auto;
    margin: 20mm;
  } 
  /* @media all {
    .page-break{
      display: none;
    }
  }
  @media print{
    .page-break{
      display: block;
      page-break-before: auto;
    }
  } */
  /* #pageFooter{
    display: table-footer-group;
  }
  #pageFooter::after{
    counter-increment: page;
    content: counter(page);
  } */
  /* @page{
    @bottom-left{
      content: counter(page) "/" counter(pages);
    }
  } */
  .Header_deisolation {
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color:#deebf7;
    color: #404040;
    padding: 1em;
    /* margin-bottom: 2em; */
    height: 15vh;
   
  }
  
  
  .logo_deisolation{
   height: 100px;
   padding-right: 20%;
   display: -webkit-flex;
   display: flex;
   
  }
  .logo2_deisolation{
    height: 100px;
   padding-left: 20%;
    display: -webkit-flex;
    display: flex;
   
   }
   .Title_deisolation{
     text-align: center;
     margin-top: 8px;
     font-size: 20px !important;
     font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
    
   }
   .Header2_deisolation{
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color: #203864;
    color: rgb(12, 12, 12);
    padding: 1em;
    margin-bottom: 2em;
    height: 6vh;
  }
   .Title2_deisolation{
    
    text-align: center;
    margin-top:0px;
    color: #fffef9;
    font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
     font-size: 18;
     white-space: nowrap;
    
   }
   .Date_deisolation{
    padding-right: 25%;
    color: #fffef9;
    
   }
   .Time_deisolation{
     padding-left:25%;
     color: #fffef9;
   }
.MuiDataGrid-row {
  color: #fff;
}
.MuiDataGrid-cellLeft {
  text-align: center;
}
.MuiTablePagination-toolbar {
  color: #fff;
}
.MuiTablePagination-menuItem {
  color: #fff;
}
.MuiTablePagination-actions {
  color: #fff;
}
.MuiIconButton-label {
  color: #fff;
}
.MuiTypography-body1 {
  color: goldenrod;
}
.modal-content {
  width: auto;
  size: A3 landscape !important;
  position: relative;
  font-size: x-small;
  right: 10px;
 
}
/* #modal-dailog ::after {
  margin-right: 50%;
} */

@media print {
  @page {
    size: A3 landscape !important;
    margin: 10% 10%;
    
    
  }
 
}

table,
th,
td {
  border: 1px solid black;
  font-weight: 100;
}
@media print {
  html,
  body {
    height: auto !important;
    height: initial !important;
    overflow: visible !important;
    overflow: initial !important;
     -webkit-print-color-adjust: exact; 
     background-color: #fff !important; 
     margin-left:2px;
      margin-right: 10x; 
    width: auto !important;
    /* //border: 1px solid blue; 
    margin: 5px ;
    -webkit-print-content-adjust: exact*/
  }
  
 
}

 .table thead{
    background-color: #b0b2b4 !important;
  }
/* @media print {
  h1 {
    background-color:#404040;
    /* background-image:url("img/404040.png"); 
    background-repeat:repeat;
    box-shadow: inset 0 0 0 1000px #404040;
    border:30px solid #404040;
    height:0;
    width:100%;
    color:#FFFFFF !important;
    margin:0 -20px;
    line-height:0px;
} */




@page {
  size: auto;
  /* margin: 20mm; */
}

#table {
  max-width: 2480px;
  width: 100%;
}
#table td {
  width: auto;
  overflow: hidden;
  /* word-wrap: break-word; */
}
/* #table th {
  color:#fff;
  background-color: crimson;
} */
.Header {
  box-sizing: border-box;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  /* align-items: baseline; */
  background-color:#deebf7;
  color: #404040;
  padding: 1em;
  /* margin-bottom: 2em; */
  height: auto;
 
}


.logo_contact{
 height: 100px;
 padding-right: 20%;
 display: -webkit-flex;
 display: flex;
 
}
.logo2_contact{
  height: 100px;
 padding-left: 20%;
  display: -webkit-flex;
  display: flex;
 
 
 }
 .Title{
   text-align: center;
   margin-top: 30px;
   font-size: 30px;
   font-weight: 600;
   font-family:Georgia, 'Times New Roman', Times, serif;
 }
 .Header2{
  box-sizing: border-box;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  /* align-items: baseline; */
  background-color: #203864;
  color: rgb(12, 12, 12);
  padding: 1em;
  margin-bottom: 2em;
  height: 6vh;
}
 .Title2{
  
  text-align: center;
  margin-top:0px;
  color: #fffef9;
  font-weight: 600;
   font-family:Georgia, 'Times New Roman', Times, serif;
   /* font-size: 25; */
  
 }
 .Date{
  padding-right: 25%;
  color: #fffef9;
  
 }
 .Time{
   padding-left:25%;
   color: #fffef9;
 }
 

.MuiDataGrid-row {
  color: #fff;
}
.MuiDataGrid-cellLeft {
  text-align: center;
}
.MuiTablePagination-toolbar {
  color: #fff;
}
.MuiTablePagination-menuItem {
  color: #fff;
}
.MuiTablePagination-actions {
  color: #fff;
}
.MuiIconButton-label {
  color: #fff;
}

@media print {
    @page { size: landscape; }
  }
  
  table, th, td {
    border: 1px solid black;
  }
   @media print {
    html, body {
      height : auto !important;
      height : initial !important;
      overflow: visible !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @page{
    size: auto;
    margin: 20mm;
  } 
  /* @media all {
    .page-break{
      display: none;
    }
  }
  @media print{
    .page-break{
      display: block;
      page-break-before: auto;
    }
  } */
  /* #pageFooter{
    display: table-footer-group;
  }
  #pageFooter::after{
    counter-increment: page;
    content: counter(page);
  } */
  /* @page{
    @bottom-left{
      content: counter(page) "/" counter(pages);
    }
  } */
  .Header_deisolation {
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color:#deebf7;
    color: #404040;
    padding: 1em;
    /* margin-bottom: 2em; */
    height: 15vh;
   
  }
  
  
  .logo_deisolation{
   height: 90px;
   padding-right: 20%;
   display: -webkit-flex;
   display: flex;
   
  }
  .logo2_deisolation{
    height: 90px;
   padding-left: 20%;
    display: -webkit-flex;
    display: flex;
   
   }
   .Title_deisolation{
     text-align: center;
     margin-top: -8px;
     font-size: 20px !important;
     font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
   }
   .Header2_deisolation{
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color: #203864;
    color: rgb(12, 12, 12);
    padding: 1em;
    margin-bottom: 2em;
    height: 6vh;
  }
   .Title2_deisolation{
    
    text-align: center;
    margin-top:0px;
    color: #fffef9;
    font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
     font-size: 18;
    
   }
   .Date_deisolation{
    padding-right: 25%;
    color: #fffef9;
    
   }
   .Time_deisolation{
     padding-left:25%;
     color: #fffef9;
   }
.MuiDataGrid-row {
  color: #fff;
}
.MuiDataGrid-cellLeft {
  text-align: center;
}
.MuiTablePagination-toolbar {
  color: #fff;
}
.MuiTablePagination-menuItem {
  color: #fff;
}
.MuiTablePagination-actions {
  color: #fff;
}
.MuiIconButton-label {
  color: #fff;
}

@media print {
    @page { size: landscape; }
  }
  
  table, th, td {
    border: 1px solid black;
  }
   @media print {
    html, body {
      height : auto !important;
      height : initial !important;
      overflow: visible !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @page{
    size: auto;
    margin: 20mm;
  } 
  /* @media all {
    .page-break{
      display: none;
    }
  }
  @media print{
    .page-break{
      display: block;
      page-break-before: auto;
    }
  } */
  /* #pageFooter{
    display: table-footer-group;
  }
  #pageFooter::after{
    counter-increment: page;
    content: counter(page);
  } */
  /* @page{
    @bottom-left{
      content: counter(page) "/" counter(pages);
    }
  } */
  .Header_deisolation {
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color:#deebf7;
    color: #404040;
    padding: 1em;
    /* margin-bottom: 2em; */
    height: 15vh;
   
  }
  
  
  .logo_deisolation{
   height: 90px;
   padding-right: 20%;
   display: -webkit-flex;
   display: flex;
   
  }
  .logo2_deisolation{
    height: 90px;
   padding-left: 20%;
    display: -webkit-flex;
    display: flex;
   
   }
   .Title_deisolation{
     text-align: center;
     margin-top: -8px;
     font-size: 20px !important;
     font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
   }
   .Header2_deisolation{
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color: #203864;
    color: rgb(12, 12, 12);
    padding: 1em;
    margin-bottom: 2em;
    height: 6vh;
  }
   .Title2_deisolation{
    
    text-align: center;
    margin-top:0px;
    color: #fffef9;
    font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
     font-size: 18;
    
   }
   .Date_deisolation{
    padding-right: 25%;
    color: #fffef9;
    
   }
   .Time_deisolation{
     padding-left:25%;
     color: #fffef9;
   }
.MuiDataGrid-row {
  color: #fff;
}
.MuiDataGrid-cellLeft {
  text-align: center;
}
.MuiTablePagination-toolbar {
  color: #fff;
}
.MuiTablePagination-menuItem {
  color: #fff;
}
.MuiTablePagination-actions {
  color: #fff;
}
.MuiIconButton-label {
  color: #fff;
}

@media print {
    @page { size: landscape; }
  }
  
  table, th, td {
    border: 1px solid black;
  }
   @media print {
    html, body {
      height : auto !important;
      height : initial !important;
      overflow: visible !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @page{
    size: auto;
    margin: 20mm;
  } 
  /* @media all {
    .page-break{
      display: none;
    }
  }
  @media print{
    .page-break{
      display: block;
      page-break-before: auto;
    }
  } */
  /* #pageFooter{
    display: table-footer-group;
  }
  #pageFooter::after{
    counter-increment: page;
    content: counter(page);
  } */
  /* @page{
    @bottom-left{
      content: counter(page) "/" counter(pages);
    }
  } */
  .Header_deisolation {
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color:#deebf7;
    color: #404040;
    padding: 1em;
    /* margin-bottom: 2em; */
    height: 15vh;
   
  }
  
  
  .logo_deisolation{
   height: 90px;
   padding-right: 20%;
   display: -webkit-flex;
   display: flex;
   
  }
  .logo2_deisolation{
    height: 90px;
   padding-left: 20%;
    display: -webkit-flex;
    display: flex;
   
   }
   .Title_deisolation{
     text-align: center;
     margin-top: -8px;
     font-size: 20px !important;
     font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
   }
   .Header2_deisolation{
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color: #203864;
    color: rgb(12, 12, 12);
    padding: 1em;
    margin-bottom: 2em;
    height: 6vh;
  }
   .Title2_deisolation{
    
    text-align: center;
    margin-top:0px;
    color: #fffef9;
    font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
     font-size: 18;
    
   }
   .Date_deisolation{
    padding-right: 25%;
    color: #fffef9;
    
   }
   .Time_deisolation{
     padding-left:25%;
     color: #fffef9;
   }
.MuiDataGrid-row {
  color: #fff;
}
.MuiDataGrid-cellLeft {
  text-align: center;
}
.MuiTablePagination-toolbar {
  color: #fff;
}
.MuiTablePagination-menuItem {
  color: #fff;
}
.MuiTablePagination-actions {
  color: #fff;
}
.MuiIconButton-label {
  color: #fff;
}

@media print {
    @page { size: landscape; }
  }
  
  table, th, td {
    border: 1px solid black;
  }
   @media print {
    html, body {
      height : auto !important;
      height : initial !important;
      overflow: visible !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @page{
    size: auto;
    margin: 20mm;
  } 
  /* @media all {
    .page-break{
      display: none;
    }
  }
  @media print{
    .page-break{
      display: block;
      page-break-before: auto;
    }
  } */
  /* #pageFooter{
    display: table-footer-group;
  }
  #pageFooter::after{
    counter-increment: page;
    content: counter(page);
  } */
  /* @page{
    @bottom-left{
      content: counter(page) "/" counter(pages);
    }
  } */
  .Header_deisolation {
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color:#deebf7;
    color: #404040;
    padding: 1em;
    /* margin-bottom: 2em; */
    height: 15vh;
   
  }
  
  
  .logo_deisolation{
   height: 90px;
   padding-right: 20%;
   display: -webkit-flex;
   display: flex;
   
  }
  .logo2_deisolation{
    height: 90px;
   padding-left: 20%;
    display: -webkit-flex;
    display: flex;
   
   }
   .Title_deisolation{
     text-align: center;
     margin-top: -8px;
     font-size: 20px !important;
     font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
   }
   .Header2_deisolation{
    box-sizing: border-box;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: baseline; */
    background-color: #203864;
    color: rgb(12, 12, 12);
    padding: 1em;
    margin-bottom: 2em;
    height: 6vh;
  }
   .Title2_deisolation{
    
    text-align: center;
    margin-top:0px;
    color: #fffef9;
    font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
     font-size: 18;
    
   }
   .Date_deisolation{
    padding-right: 25%;
    color: #fffef9;
    
   }
   .Time_deisolation{
     padding-left:25%;
     color: #fffef9;
   }
.MuiDataGrid-row {
  color: #fff;
}
.MuiDataGrid-cellLeft {
  text-align: center;
}
.MuiTablePagination-toolbar {
  color: #fff;
}
.MuiTablePagination-menuItem {
  color: #fff;
}
.MuiTablePagination-actions {
  color: #fff;
}
.MuiIconButton-label {
  color: #fff;
}

.mainContainer {
    width: 600;
    height: 600;
    background-color: "#666";
  }
  
a {
    text-decoration: none;
}

.SideBar_SideBar__3NUFo {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
    background-color: #2d334a;
    border-right-width: 1px;
    border-right-color: #272343;
    border-right-style: solid;
}

.SideBar_SideBarToggleButton__34mmV {
    padding: 20px;
}

.SideBar_UserAvatar__XWuTL{
    height: 64px;
    width: 64px;
    border-radius: 32px;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

.SideBar_UserInfo__30Xy_ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

.SideBar_UserNameAndDesignation__1_Aty {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items:flex-start;
            align-items:flex-start;
    -webkit-flex: 1 1;
            flex: 1 1;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
}

.SideBar_UserName__3m0mo {
    font-size: 14px;
    color: #cccccc;
    text-align: left;
}

.SideBar_UserDesignation__X1NKC {
    font-size: 13px;
    color: #bbbbbb;
}

.SideBar_AccountSettingsWrapper__3bMu9 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.SideBar_AccountSettingsButton__1nxl- {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 30px;
    color: #ccc;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 450ms;
}
.SideBar_AccountSettingsButton__1nxl-:hover {
    background-color: #cccccc1c;
    padding-left: 12px;
    padding-right: 12px;
}

.SideBar_SideBarOption__2dp99 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 20px;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 500ms;
}
.SideBar_SideBarOption__2dp99:hover {
    background-color: #4f5467;
    padding-left: 30px;
}

.SideBar_SideBarOptionActive__1mtnS {
    background-color: #4f5467;
    padding-left: 30px;
}

.SideBar_SideBarOptionTitle__ZUNdw {
    padding-left: 20px;
    color: #ccc;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    -webkit-flex: 3 1;
            flex: 3 1;
    text-align: left;
}

.SideBar_SideBarOptionIcon__2gJFL {
    -webkit-flex: 1 1;
            flex: 1 1;
    font-size: 20px;
}
