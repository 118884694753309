#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: #2d334a;
  
}

.site-layout .site-layout-background {
  background: #2d334a;
}
.App{
  background-color: #2d334a;
}