@media print {
    @page { size: landscape; }
  }
  
  table, th, td {
    border: 1px solid black;
  }
   @media print {
    html, body {
      height : initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @page{
    size: auto;
    margin: 20mm;
  } 
  /* @media all {
    .page-break{
      display: none;
    }
  }
  @media print{
    .page-break{
      display: block;
      page-break-before: auto;
    }
  } */
  /* #pageFooter{
    display: table-footer-group;
  }
  #pageFooter::after{
    counter-increment: page;
    content: counter(page);
  } */
  /* @page{
    @bottom-left{
      content: counter(page) "/" counter(pages);
    }
  } */