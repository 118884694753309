@media print {
    @page { size: landscape; }
  }
  
  table, th, td {
    border: 1px solid black;
  }
   @media print {
    html, body {
      height : initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @page{
    size: auto;
    margin: 20mm;
  } 
  /* @media all {
    .page-break{
      display: none;
    }
  }
  @media print{
    .page-break{
      display: block;
      page-break-before: auto;
    }
  } */
  /* #pageFooter{
    display: table-footer-group;
  }
  #pageFooter::after{
    counter-increment: page;
    content: counter(page);
  } */
  /* @page{
    @bottom-left{
      content: counter(page) "/" counter(pages);
    }
  } */
  .Header_deisolation {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: baseline; */
    background-color:#deebf7;
    color: #404040;
    padding: 1em;
    /* margin-bottom: 2em; */
    height: 15vh;
   
  }
  
  
  .logo_deisolation{
   height: 90px;
   padding-right: 20%;
   display: flex;
   
  }
  .logo2_deisolation{
    height: 90px;
   padding-left: 20%;
    display: flex;
   
   }
   .Title_deisolation{
     text-align: center;
     margin-top: -8px;
     font-size: 20px !important;
     font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
   }
   .Header2_deisolation{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: baseline; */
    background-color: #203864;
    color: rgb(12, 12, 12);
    padding: 1em;
    margin-bottom: 2em;
    height: 6vh;
  }
   .Title2_deisolation{
    
    text-align: center;
    margin-top:0px;
    color: #fffef9;
    font-weight: 600;
     font-family:Georgia, 'Times New Roman', Times, serif;
     font-size: 18;
    
   }
   .Date_deisolation{
    padding-right: 25%;
    color: #fffef9;
    
   }
   .Time_deisolation{
     padding-left:25%;
     color: #fffef9;
   }