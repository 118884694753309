@media print {
  @page {
    size: A4 !important;
    margin: 10%;
  }
}

/* table, th, td {
  border: 1px solid black;
} */
@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    background-color: #fff !important;
    /* margin-left:2px;
    margin-right: 10x; */
    width: auto !important;
  }
}
@page {
  size: auto;
  /* margin: 20mm; */
}
.pdfTable .pdfHead .pdfTr {
  border: none !important;
}
