@media print {
  @page {
    size: A3 landscape !important;
    margin: 10% 10%;
    
    
  }
 
}

table,
th,
td {
  border: 1px solid black;
  font-weight: 100;
}
@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
     -webkit-print-color-adjust: exact; 
     background-color: #fff !important; 
     margin-left:2px;
      margin-right: 10x; 
    width: auto !important;
    /* //border: 1px solid blue; 
    margin: 5px ;
    -webkit-print-content-adjust: exact*/
  }
  
 
}

 .table thead{
    background-color: #b0b2b4 !important;
  }
/* @media print {
  h1 {
    background-color:#404040;
    /* background-image:url("img/404040.png"); 
    background-repeat:repeat;
    box-shadow: inset 0 0 0 1000px #404040;
    border:30px solid #404040;
    height:0;
    width:100%;
    color:#FFFFFF !important;
    margin:0 -20px;
    line-height:0px;
} */




@page {
  size: auto;
  /* margin: 20mm; */
}

#table {
  max-width: 2480px;
  width: 100%;
}
#table td {
  width: auto;
  overflow: hidden;
  /* word-wrap: break-word; */
}
/* #table th {
  color:#fff;
  background-color: crimson;
} */
.Header {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: baseline; */
  background-color:#deebf7;
  color: #404040;
  padding: 1em;
  /* margin-bottom: 2em; */
  height: auto;
 
}


.logo_contact{
 height: 100px;
 padding-right: 20%;
 display: flex;
 
}
.logo2_contact{
  height: 100px;
 padding-left: 20%;
  display: flex;
 
 
 }
 .Title{
   text-align: center;
   margin-top: 30px;
   font-size: 30px;
   font-weight: 600;
   font-family:Georgia, 'Times New Roman', Times, serif;
 }
 .Header2{
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: baseline; */
  background-color: #203864;
  color: rgb(12, 12, 12);
  padding: 1em;
  margin-bottom: 2em;
  height: 6vh;
}
 .Title2{
  
  text-align: center;
  margin-top:0px;
  color: #fffef9;
  font-weight: 600;
   font-family:Georgia, 'Times New Roman', Times, serif;
   /* font-size: 25; */
  
 }
 .Date{
  padding-right: 25%;
  color: #fffef9;
  
 }
 .Time{
   padding-left:25%;
   color: #fffef9;
 }
 
