.MuiDataGrid-row {
  color: #fff;
}
.MuiDataGrid-cellLeft {
  text-align: center;
}
.MuiTablePagination-toolbar {
  color: #fff;
}
.MuiTablePagination-menuItem {
  color: #fff;
}
.MuiTablePagination-actions {
  color: #fff;
}
.MuiIconButton-label {
  color: #fff;
}
