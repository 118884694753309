.MuiDataGrid-row {
  color: #fff;
}
.MuiDataGrid-cellLeft {
  text-align: center;
}
.MuiTablePagination-toolbar {
  color: #fff;
}
.MuiTablePagination-menuItem {
  color: #fff;
}
.MuiTablePagination-actions {
  color: #fff;
}
.MuiIconButton-label {
  color: #fff;
}
.MuiTypography-body1 {
  color: goldenrod;
}
.modal-content {
  width: auto;
  size: A3 landscape !important;
  position: relative;
  font-size: x-small;
  right: 10px;
 
}
/* #modal-dailog ::after {
  margin-right: 50%;
} */
